@import '../../../styles/vars.css';

.articleContainer {
  min-height: 650px;
  .title {
    margin-bottom: 20px;
    display: flex;
    line-height: 55px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    > h1 {
      font-family: 'Magra', sans-serif;
      margin: 0;
      font-size: 4.5rem;
      font-weight: bolder;
    }
    > h4 {
      font-family: 'Magra', sans-serif;
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 2.2rem;
      font-weight: normal;
      color: var(--grey);
    }
  }
  .articleWrapper {
    width: 100%;
    .galleryWrapper {
      width: 50%;
      float: right;
      margin-left: 15px;
      padding: 5px;
    }
    p {
      display: block;
      font-size: 1.3rem;
      text-align: justify;
    }
  }

  .noImage {
    width: 100%;
  }
  p {
    text-align: justify;
    white-space: pre-wrap;
  }
  .smallFont {
    font-size: 1.3rem;
  }
  .mediumFont {
    font-size: 1.5rem;
  }
  .bigFont {
    font-size: 1.8rem;
  }
}

.articleContainerSingleImage {
  border-bottom: 1px solid transparent;
}
.articleContainerNoImage {
  min-height: 0px !important;
}

@media only screen and (max-width: 1100px) {
  .galleryWrapper {
    width: 100% !important;
  }
  .articleContainer {
    .title {
      font-size: 2.2rem;
      line-height: 40px;
      align-items: center;
      text-align: justify;
      justify-content: center;
      > h1 {
        font-size: 2.2rem;
      }
      > h4 {
        font-size: 1.5rem;
        color: var(--grey);
        > i {
          margin-top: 3px;
        }
      }
    }
    p {
      text-align: justify;
    }
    .smallFont {
      font-size: 1.3rem;
    }
    .mediumFont {
      font-size: 1.5rem;
    }
    .bigFont {
      font-size: 1.8rem;
    }
  }
}

.separator {
  margin: 40px 0;
  width: 100%;
  border-bottom: 1px solid rgb(163, 161, 161);
}

img {
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}
