@import '../../../styles/vars.css';

.contrastChangerWrapper {
  margin-bottom: 20px;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 3px 3px 4px;
    height: 45px;
    width: 45px;
    border: 2.5px solid var(--orange);
    border-radius: 10px;
    background-color: var(--white);
    color: var(--orange);
    font-weight: 700;
    &:hover {
      cursor: pointer;
      color: var(--white);
      background-color: var(--orange);
    }
  }
  .small {
    font-size: 16px;
  }
  .medium {
    font-size: 20px;
  }
  .big {
    font-size: 24px;
  }
}

@media only screen and (max-width: 1100px) {
  .fontSizeChangerWrapper {
    top: 18px;
    left: 20px;

    .small {
      font-size: 16px;
    }
    .medium {
      font-size: 20px;
    }
    .big {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .fontSizeChangerWrapper {
    top: 18px;
    left: 20px;
  }
}
