@import '../../styles/vars.css';
.wrapper {
  font-family: 'Magra', sans-serif;
  display: flex;
  flex-direction: column;
  .layoutContainer {
    min-height: calc(100vh - 200px - 120px);
    margin-top: 150px;
    width: 100vw;
    display: flex;
    justify-content: center;
    .layoutWrapper {
      padding: 20px;
      max-width: 1350px;
      height: 100%;
      width: 100%;
    }
  }
}

.homepageWrapper {
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url('../../images/paper.jpg');
  background-position: 0 -5000px;
  color: var(--white);
  .homepageLayoutContainer {
    height: calc(100vh - 200px - 130px);
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 1100px) {
  .wrapper {
    .layoutContainer {
      margin-top: 50px;
    }
  }
  .homepageWrapper {
    .homepageLayoutContainer {
      height: calc(100vh - 108px);
    }
  }
}
.contrastChange {
  background-color: var(--black);
  color: var(--white);
}
