@import '../../../styles/vars.css';
.toTopButton {
  position: fixed;
  right: 90px;
  bottom: 40px;
  font-size: 60px !important;
  z-index: 1;
  color: var(--black);
  &:hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 1100px) {
  .toTopButton {
    font-size: 45px !important;
    right: 40px;
    bottom: 40px;
  }
}
.contrastChange {
  color: var(--grey);
}
