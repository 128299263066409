@import '../../../styles/vars.css';
.headerContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100px;
  top: 0;
  left: 0;
  transition-timing-function: ease-in;
  transition: 0.5s;
  .hamburgerBtn {
    display: none;
  }
  .headerWrapper {
    display: flex;
    justify-content: space-around;
    width: 70vw;
    min-width: 1100px;
    .logo {
      width: 80px;
      border-radius: 50%;
      cursor: pointer;
    }
    .rowHeader {
      margin: 0;
      text-transform: uppercase;
      font-weight: 700;
      list-style: none;
      display: flex;
      width: 1000px;
      justify-content: space-evenly;
      align-items: center;
      color: var(--orange);
      li,
      a {
        display: block;
        cursor: pointer;
        padding: 15px;
        color: var(--orange);
        font-size: 16px;
      }
      li:hover,
      a:hover {
        color: var(--white);
        background-color: var(--darkOrange);
      }

      .active {
        border-bottom: 3px solid var(--darkOrange);
      }
    }
  }
}

.headerHomePage {
  position: static;
  height: 200px;
  .headerWrapper {
    .logo {
      width: 180px;
      border-radius: 50%;
      cursor: pointer;
    }
    .rowHeader {
      color: var(--orange);
      li,
      a {
        color: var(--white);
      }
      li:hover,
      a:hover {
        color: var(--white);
        background-color: var(--grey);
      }
    }
  }
}

.scrollActtive {
  background-color: var(--activeScroll);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  .headerWrapper {
    ul {
      li,
      a {
        color: var(--white) !important;
      }
      li:hover,
      a:hover {
        background-color: transparent !important;
        border-bottom: 3px solid var(--grey);
      }
      .active {
        border-bottom: 3px solid var(--grey);
        color: var(--white);
        background-color: transparent;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .headerContainer {
    height: 0;

    .hamburgerBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      display: none;
      z-index: 100;
      &:hover {
        cursor: pointer;
      }
    }
    .hamburgerBtn {
      display: block !important;
    }

    .rowHeader {
      display: none !important;
    }
    //TODO logo
    .headerWrapper {
      // height: 100px;
      .logo {
        // width: 110px !important;
        // position: relative;
        // top: 0px;
        // left: 50%;
        // transform: translate(-50%, -50%);
        display: none;
      }
    }
  }
  .headerHomePage {
    .headerWrapper {
      .logo {
        width: 110px !important;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .headerContainerPhone {
    height: 100%;
    position: absolute;
    z-index: 100;
    background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)),
      url('../../../images/paper.jpg');
    background-position: 0 -5000px;

    .headerWrapper {
      width: 100%;
      min-width: auto !important;
      .logo {
        display: none;
      }
      .colHeader {
        padding: 10px 0 !important;
        display: flex !important;
        flex-direction: column;
        width: 100% !important;
        justify-content: center;
        z-index: 100;
        padding: 0;
        li {
          border-bottom: 1.5px solid var(--white);
        }
        li,
        a {
          color: var(--white);
        }
        li:hover,
        a:hover {
          color: var(--white);
          background-color: var(--grey);
        }
        .active {
          border-bottom: 3px solid var(--white);
        }
      }
    }
  }
}
