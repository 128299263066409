@import '../../styles/vars.css';

.pageWrapper {
  width: 100%;
  height: 100%;
  min-height: 620px;
  div:nth-of-type(2) {
    display: flex;
    justify-content: center;
  }
  .emptyPage {
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 480px;
  }
  .paginationArticlesWrapper {
    min-height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
