@import '../../../styles/vars.css';
.footerContainer {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .footerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: var(--white);
    border-radius: 20px 20px 0px 0;
    width: 850px;
    .leftSide {
      width: 650px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      color: var(--black);
      padding-bottom: 5px;
      > p {
        padding: 0;
        margin: 0;
      }
      .footerImage {
        width: 550px;
        border-radius: 20px;
      }
    }
    .rightSide {
      color: var(--black);
      height: 60px;
      > p {
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .footerContainer {
    font-size: 10px;
    height: 90px;
    .footerWrapper {
      padding: 5px;
      gap: 0px;
      flex-direction: column;
      width: 100%;
      border-radius: 0%;
      .leftSide {
        width: 100%;
        text-align: center;
        .footerImage {
          width: 100%;
        }
      }
      .rightSide {
        order: 2;
        height: 10px !important;
        > p {
          display: inline-block !important;
          text-align: center;
          margin-left: 10px;
        }
      }
    }
  }
}
