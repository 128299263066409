@import '../../styles/vars.css';

.homePageContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .layoutWrapper {
    padding: 20px;
    height: 100%;
    max-width: 1350px;
    color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      padding: 60px 0 20px 0;
      font-weight: 700;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > p {
        padding: 0;
        margin: 0;
        font-size: 20px;
      }
      > a {
        font-size: 20px;
        color: black;
      }
      > h1 {
        font-size: 76px;
        font-family: 'Magra', sans-serif;
      }

      > p:nth-of-type(3) {
        font-size: 36px;
      }
      > p:nth-of-type(4) {
        margin-top: 80px;
      }
      > span:first-of-type {
        font-size: 20px;
        > p {
          display: inline;
          color: black;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .homePageContainer {
    height: 100%;
    .layoutWrapper {
      width: 100%;
      .text {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > p {
          font-size: 10px;
        }
        > a {
          font-size: 10px;
        }
        > h1 {
          font-size: 38px;
          font-family: 'Magra', sans-serif;
        }

        > p:nth-of-type(3) {
          font-size: 18px;
          text-align: center;
        }
        > p:nth-of-type(4) {
          margin-top: 40px;
        }
        > span:first-of-type {
          font-size: 10px;
          > p {
            display: inline;
          }
        }
      }
    }
  }
}
