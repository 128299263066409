@import '../../../styles/vars.css';
.availabilityWrapper {
  display: flex;
  gap: 10px;
  width: 100px;
}

@media only screen and (max-width: 1100px) {
  .availabilityWrapper {
    position: relative;
    top: -55px;
  }
}
